import React from "react"
import styles from "../common.module.scss"
import { motion } from "framer-motion"
import ProjectStatusBar from "../common/ProjectStatusBar"

export default function index() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      key="workKioskVenu"
    >
      <div className={styles.devProjectPage}>
        <header className={styles.pageHeader}>
          <h1 className={styles.pageTitle}>Kiosk Venue Web Application</h1>

          <ProjectStatusBar
            statusText="Final Revision"
            demoUrl="https://isometr.icu"
            githubUrl="https://github.com/isaacd8k/nextjs-demo"
          />
        </header>

        <main className={styles.mainContent}>
          {/* Specifications / requirements */}
          <section className={styles.mainSection}>
            <h2>Project Spec</h2>
            <p>
              TASK: build a multilingual kiosk web app for an events venue. This
              kiosk will run locally in the venue. End-users will see and
              interact with content via a 24" touch screen interface. This app
              should:
              <ul>
                <li>Show basic venue information in a modern UI</li>
                <li>
                  Support basic app functions such as links, back button, etc
                </li>
                <li>Run on modern browsers</li>
                <li>
                  Be easily updatable by authorized users via a user interface
                </li>
                <li>
                  Support multilingual content, starting with Spanish and
                  English, with the possibility of adding more in the future
                </li>
                <li>
                  Support both informational/static and interactive content
                </li>
                <li>
                  Support basic kiosk features such as periodically resetting
                  the session after a period of inactivity and gracefully
                  handling network issues
                </li>
              </ul>
            </p>
          </section>

          {/* Scope of work */}
          <section className={styles.mainSection}>
            <h2>Scope of work</h2>
            <p>
              Design work will be done by a small design team. Based on
              requirements, this project will be handled with a customizable
              headless CMS. This will help eliminate the complexity of creating
              a custom CMS and instead will allow the focus to remain on rapid
              development and prototyping. Also, a CMS with i18n will take care
              of the complexity of supporting multi-lingual content.
              <br />
              <br />
              Stakeholders want content and data to remain on-prem for now, so
              CMS and platform should support self-hosting. Also, since this
              kiosk will be a proof- of-concept, app and data requirements are
              expected to be fluid and changing.
              <br />
              <br />
              <h6>Chosen technologies:</h6>
              <ul>
                <li>CMS: Strapi</li>
                <li>Server/Platform: NextJS</li>
                <li>Frontend: NextJS pages with ReactJS</li>
                <li>SSG Data Sourcing: Strapi CMS via GraphQL</li>
              </ul>
            </p>
            {/* What will I be building? What will it include/not include
          what are the foreseen costs/dev time associated with the requirements */}
            {/* Sample pics of final design */}
          </section>

          {/* Development stages */}
          <section className={styles.mainSection}>
            <h2>Development stages</h2>
            <p>
              There are some stages that require waiting for stakeholders and
              designers. However, some work can begin in parallel.
              <br />
              <ol>
                <li>Research tools and technologies</li>
                <li>Write tentative data requirements</li>
                <li>
                  Receive draft mockups from design team and begin prototype
                  development
                </li>
                <li>
                  Present to stakeholders and iterate steps 3 and 4 as necessary
                </li>
                <li>
                  Begin more refined development with better-defined app
                  requirements
                </li>
                <li>
                  Usability / end-user testing (prepare for launch & delivery)
                </li>
                <li>Delivery</li>
              </ol>
            </p>
            {/* In what order will I approach the steps/stages. Why?
        What is the projected timeline & justification */}
          </section>

          {/* Progress */}
          <section className={styles.mainSection}>
            <h2>Progress</h2>
            <p>
              We received feedback on first iteration of prototype and currently
              building out the second iteration in collaboration with the design
              team.
            </p>
            {/* Where am I */}

            {/* Next steps */}

            {/* Challenges */}
          </section>
        </main>
      </div>
    </motion.div>
  )
}
